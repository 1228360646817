import {
  appendDataIntoSheet,
  bookSlot,
  createOrder,
  getIpData,
  validateCoupons,
} from "../lib/api";
import { UiActions } from "./slices/ui-slice";
import { userActions } from "./slices/user-slice";
import { handlePayment } from "../utils/payment-utils";
import store from "./store";
import { couponActions } from "./slices/coupon-slice";
import {
  calculateRemainingDuration,
  filterObject,
  getCurrentDate,
} from "../utils/ui-utils";
import { ipActions } from "./slices/ip-slice";
import moment from "moment";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  getEventName,
  getFbpCookie,
  removeKeysFromObject,
} from "../utils/analytic-utils";
import { ANALYTICS_KEYS_TO_REMOVE } from "../utils/constants/global-constants";

export const createUserDocument = (paymentType, data) => {
  return async (dispatch) => {
    dispatch(UiActions.setError(null));
    dispatch(UiActions.setStatus("Sending UserDetails..."));
    dispatch(UiActions.setIsLoading(true));
    try {
      dispatch(UiActions.setStatus("Preparing Payment Gateway..."));
      let payload = {
        paymentType,
        selectedPlanId: data.selectedPlanId,
        name: data.name,
        email: data.email,
        whatsAppNumber: data.whatsAppNumber,
        lmp: data.lmp,
        utmSource: data.extras.utm_source,
        utmCampaign: data.extras.utm_campaign,
        utmContent: data.extras.utm_content,
        programStartDate: data.programStartDate,
        appliedCoupon: data?.appliedCoupon,
      };

      console.log("payload", data);

      if (data?.dateOfBirth && data?.dateOfBirth.trim().length > 0) {
        payload = {
          ...payload,
          extraNotes: {
            ...payload.extraNotes,
            dateOfBirth: data?.dateOfBirth,
          },
        };
      }

      const orderDetails = await createOrder(payload);
      dispatch(userActions.appendPropsInExtras(orderDetails));
      dispatch(UiActions.setShouldExecuteRazorpayScript(true));

      dispatch(UiActions.setStatus(null));
      dispatch(UiActions.setIsLoading(false));
    } catch (err) {
      dispatch(UiActions.setIsLoading(false));
      dispatch(
        UiActions.setError(
          err.message || "Couldn't save users Data!.Try after a while."
        )
      );
    }
  };
};

export const openPlanPurchasePaymentPage = (programType) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const cCodeIsValid = store.getState().coupon.isValid;
    const cCode = store.getState().coupon.code;
    const remainingDuration = calculateRemainingDuration(userData.lmp);
    const selectedPlan = store
      .getState()
      .plans.plans.find((p) => p._id === userData.selectedPlanId);
    window.fbq(
      "track",
      "Lead",
      removeKeysFromObject({ ...userData }, ANALYTICS_KEYS_TO_REMOVE)
    );

    let notes = {};

    if (userData.lmp && userData.lmp.trim().length > 0) {
      notes = {
        ...userData.extras,
        appliedCoupon: cCodeIsValid ? cCode : "",
        plan: userData.selectedPlanId,
        actualAmountToPay: userData.extras.amount,
        lmpOrEdd: userData.lmp,
        programStartDate: userData.programStartDate
          ? moment.utc(userData.programStartDate).format()
          : new Date().toJSON(),
        programEndDate: new Date(
          new Date().getTime() +
            Number(selectedPlan?.validityInDays) * 24 * 60 * 60 * 1000
        ).toJSON(),
        numberOfMonthsPayFor:
          selectedPlan.planType === "time-bound"
            ? +selectedPlan.validityInDays === 93
              ? "3 months"
              : "1 month"
            : `${remainingDuration.remainingMonths} months & ${remainingDuration.remainingDays} days`,
        source: "website",
        paymentTermType: selectedPlan.planType,
        PlanValidityInDays: selectedPlan.validityInDays,
      };
    } else {
      notes = { ...userData, ...userData.extras };
    }

    await handlePayment(
      {
        order: userData.extras.order,
        amount: userData.extras.amount,
      },
      userData,
      filterObject(notes),
      (data) => {
        dispatch(userActions.appendPropsInExtras({ appliedCoupon: cCode }));
        dispatch(userActions.appendPropsInExtras(data));
        console.log("payment successful called");
        dispatch(UiActions.setIsPaymentDone(true));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
        // dispatch(sendFbEvent(programType));
        // dispatch(UiActions.setShouldExecuteRazorpayScript(false))
      },
      () => {
        dispatch(UiActions.setShouldExecuteRazorpayScript(false));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
      },
      programType || "WCP" //TODO : remove default value
    );
  };
};

export const openPlanRenewPaymentPage = (programType) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const remainingDuration = calculateRemainingDuration(userData.lmp);
    const selectedPlan = store
      .getState()
      .plans.plans.find((p) => p._id === userData.selectedPlanId);
    window.fbq(
      "track",
      "Lead",
      removeKeysFromObject({ ...userData }, ANALYTICS_KEYS_TO_REMOVE)
    );

    let notes = {};
    const programStartMoment = userData.currentPlanEndDate
      ? moment.utc(userData.currentPlanEndDate)
      : moment().startOf("day");

    if (userData.lmp && userData.lmp.trim().length > 0) {
      notes = {
        ...userData.extras,
        plan: userData.selectedPlanId,
        actualAmountToPay: userData.extras.amount,
        lmpOrEdd: userData.lmp,
        programStartDate: programStartMoment.format(),
        programEndDate: programStartMoment
          .add(selectedPlan?.validityInDays || 0, "days")
          .format(),
        numberOfMonthsPayFor:
          selectedPlan.planType === "time-bound"
            ? +selectedPlan.validityInDays === 93
              ? "3 months"
              : "1 month"
            : `${remainingDuration.remainingMonths} months & ${remainingDuration.remainingDays} days`,
        source: "website",
        paymentTermType: selectedPlan.planType,
        PlanValidityInDays: selectedPlan.validityInDays,
      };
    } else {
      notes = { ...userData, ...userData.extras };
    }

    await handlePayment(
      {
        order: userData.extras.order,
        amount: userData.extras.amount,
      },
      userData,
      filterObject(notes),
      (data) => {
        dispatch(userActions.appendPropsInExtras(data));
        console.log("payment successful called");
        dispatch(UiActions.setIsPaymentDone(true));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
        // dispatch(sendFbEvent(programType));
        // dispatch(UiActions.setShouldExecuteRazorpayScript(false))
      },
      () => {
        dispatch(UiActions.setShouldExecuteRazorpayScript(false));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
      },
      programType || "WCP" //TODO : remove default value
    );
  };
};

export const openProgramRegistrationPaymentPage = (paymentType, date, time) => {
  return async (dispatch) => {
    //create order
    dispatch(UiActions.setIsLoading(true));
    dispatch(UiActions.setStatus("Please wait..."));
    try {
      const orderDetails = await createOrder({
        paymentType,
        extraNotes: {
          date: date,
          time: time,
          segment: store.getState().user.extras?.segment,
          couponCode: store.getState().coupon.code,
        },
      });
      console.log("orderDetails " + JSON.stringify(orderDetails));
      dispatch(userActions.appendPropsInExtras(orderDetails));
      dispatch(UiActions.setShouldExecuteRazorpayScript(true));
      dispatch(UiActions.setIsLoading(false));
      dispatch(UiActions.setStatus(null));
    } catch (err) {
      console.error("Error creating order", err);
      dispatch(UiActions.setIsLoading(false));
      dispatch(UiActions.setStatus(err.message));
    }
    const userData = store.getState().user;
    await handlePayment(
      {
        order: userData.extras.order,
        amount: userData.extras.amount,
      },
      userData,
      filterObject({
        ...userData,
        ...userData.extras,
        extras: null,
      }),
      () => {
        dispatch(sendFbEvent(paymentType));
        dispatch(UiActions.setIsPaymentDone(true));
        window.fbq(
          "track",
          "Lead",
          removeKeysFromObject({ ...userData }, ANALYTICS_KEYS_TO_REMOVE)
        );
        dispatch(UiActions.setIsLoading(false));
        // dispatch(UiActions.setShouldExecuteRazorpayScript(false))
      },
      () => {
        dispatch(UiActions.setShouldExecuteRazorpayScript(false));
        dispatch(UiActions.setIsLoading(false));
      },
      paymentType
    );
  };
};

const hash = (str) =>
  str ? CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex) : null;

export const sendFbEvent = (event) => {
  return async (dispatch) => {
    const eventName = getEventName(event) || event;
    const shouldUseNewPixel =
      eventName === "bs_payment_done" ||
      eventName === "preconception-slot-booking";
    if (!eventName) {
      return;
    }
    const ipData = store.getState().ip.data;
    const userData = store.getState().user;
    const _userAgent = navigator.userAgent;
    try {
      // dispatch(UiActions.setIsLoading(true)); // ! make it happen conditionally
      const _data = {
        event_id: userData.whatsAppNumber || Date.now() + eventName,
        event_name: eventName,
        event_time: Math.trunc(Date.now() / 1000),
        event_source_url: window.location.href,
        action_source: "website",
        em: [hash(userData.email)],
        ph: [hash(userData.whatsAppNumber)],
        ct: [hash(ipData.city)],
        st: [hash(ipData.region)],
        fn: [hash(userData.name)],
        // external_id: [hash(getExternalID(event))],
        country: [hash(ipData.country_name)],
        client_ip_address: ipData.ip,
        // client_user_agent: _userAgent,
      };

      // if (event === "superparenting-class") {
      //   window.fbq("init", "840627999842586", {
      //     em:userData.email,
      //     ph:userData.whatsAppNumber,
      //     ct:ipData.city,
      //     st:ipData.region,
      //     fn:userData.name,
      //     country: ipData.country_name,
      //     client_ip_address: ipData.ip,
      //     client_user_agent: _userAgent,
      //   });

      //   window.fbq(
      //     "trackCustom",
      //     eventName,
      //     {},
      //     {
      //       event_id: _data.event_id,
      //       external_id: _data.external_id,
      //       event_time: _data.event_time,
      //       event_source_url: _data.event_source_url,
      //       action_source: _data.action_source,
      //     }
      //   );
      // }

      const response = await axios.post(
        "https://us-central1-pruoo-app.cloudfunctions.net/sendFbEvents",
        {
          payload: {
            data: [
              {
                event_id: _data.event_id,
                event_name: _data.event_name,
                event_time: _data.event_time,
                // event_source_url: _data.event_source_url,
                action_source: _data.action_source,
                user_data: {
                  em: _data.em,
                  ph: _data.ph,
                  ct: _data.city,
                  st: _data.st,
                  fn: _data.name,
                  external_id: _data.external_id,
                  country: _data.country,
                  client_ip_address: _data.ip,
                  // client_user_agent: _data.client_user_agent,
                  fbp: getFbpCookie(),
                },
              },
            ],
            // test_event_code: "TEST36427",
          },
          pixel_id: shouldUseNewPixel ? "435709519370547" : "840627999842586",
        }
      );
      // dispatch(UiActions.setIsPaymentDone(true)); // ! make it happen conditionally
      console.log(JSON.stringify(response?.data));
    } catch (error) {
      console.log(Error);
    } finally {
      // dispatch(UiActions.setIsLoading(false)); // ! make it happen conditionally
    }
  };
};

export const sendFbEventWithoutUserDataState = (event, userData) => {
  return async (dispatch) => {
    const eventName = getEventName(event) || event;
    const shouldUseNewPixel = eventName === "bs_payment_done";
    if (!eventName) {
      return;
    }
    const ipData = store.getState().ip.data;
    const _userAgent = navigator.userAgent;
    try {
      const _data = {
        event_id: userData.event_id || userData.phone || Date.now() + eventName,
        event_name: eventName,
        event_time: Math.trunc(Date.now() / 1000),
        event_source_url: window.location.href,
        action_source: "website",
        em: [hash(userData.email)],
        ph: [hash(userData.phone)],
        ct: [hash(ipData.city)],
        st: [hash(ipData.region)],
        fn: [hash(userData.name)],
        // external_id: [hash(getExternalID(event))],
        country: [hash(ipData.country_name)],
        client_ip_address: ipData.ip,
        // client_user_agent: _userAgent,
      };
      const response = await axios.post(
        "https://us-central1-pruoo-app.cloudfunctions.net/sendFbEvents",
        {
          payload: {
            data: [
              {
                event_id: _data.event_id,
                event_name: _data.event_name,
                event_time: _data.event_time,
                // event_source_url: _data.event_source_url,
                action_source: _data.action_source,
                user_data: {
                  em: _data.em,
                  ph: _data.ph,
                  ct: _data.city,
                  st: _data.st,
                  fn: _data.name,
                  external_id: _data.external_id,
                  country: _data.country,
                  client_ip_address: _data.ip,
                  // client_user_agent: _data.client_user_agent,
                  fbp: getFbpCookie(),
                },
              },
            ],
            // test_event_code: "TEST36427",
          },
          pixel_id: shouldUseNewPixel ? "435709519370547" : "840627999842586",
        }
      );
      console.log(JSON.stringify(response?.data));
    } catch (error) {
      console.log(Error);
    }
  };
};

export const applyCouponCode = () => {
  return async (dispatch) => {
    const { isApplied, isProcessing } = store.getState().coupon;
    const { lmp, selectedPlanId } = store.getState().user;
    //if already applied set prompt and not processing  ("already plied") return
    if (isApplied) {
      dispatch(couponActions.setPrompt("Coupon is Already Applied!"));
      return;
    }
    //if processing return
    if (isProcessing) {
      return;
    }

    dispatch(couponActions.setIsProcessing(true));
    dispatch(couponActions.setPrompt("Applying..."));
    const cCode = store.getState().coupon.code;
    const programStartDate = store.getState().user.programStartDate;
    const couponData = await validateCoupons(
      cCode,
      selectedPlanId,
      lmp,
      programStartDate
    );
    console.log(couponData);
    if (couponData && couponData.isValid) {
      console.log("something..");
      console.log(couponData);
      dispatch(couponActions.setIsValid(true));
      dispatch(couponActions.setError(null));
      dispatch(couponActions.setDiscount(couponData.discount));
      dispatch(
        couponActions.setDiscountOnPerMonthPlan(
          couponData.discountOnPerMonthPlan
        )
      );
      dispatch(couponActions.setDiscountedPlanId(couponData.discountedPlanId));
      dispatch(couponActions.setIsApplied(true));
      dispatch(couponActions.setPrompt("Coupon applied !!"));
    } else {
      dispatch(couponActions.setIsValid(false));
      dispatch(
        couponActions.setPrompt(
          "The coupon code entered is not valid for this plan. Perhaps you used the wrong coupon code?"
        )
      );
    }
    //set processing false
    dispatch(couponActions.setIsProcessing(false));
  };
};

export const appendFormDataIntoSheet = (sheetName, extrasColValues) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const ipData = store.getState().ip.data;
    const name = userData.name;
    const email = userData.email;
    const whatsAppNumber = userData.whatsAppNumber;
    const lmp = userData.lmp;
    const monthOfPreg = userData?.monthOfPregnancy;
    const utm_campaign = userData.extras["utm_campaign"] || "n/a";
    const utm_source = userData.extras["utm_source"] || "n/a";
    const model = userData.extras["model"];
    const brand = userData.extras["brand"];
    let _notes = [
      getCurrentDate(),
      name,
      email,
      whatsAppNumber,
      utm_campaign || "",
      utm_source || "",
      lmp || monthOfPreg,
    ];
    if (extrasColValues && extrasColValues.length > 0) {
      console.log("Somethinggg ");
      console.log(extrasColValues);
      _notes = [..._notes, ...extrasColValues];
    }
    let notes = [
      [..._notes, ipData.city, ipData.region, model, brand].filter(
        (value) => value.trim().length > 0
      ),
      // .filter(
      //   (p) => p.toString().trim().length !== 0
      // ),
    ];
    const res = { notes, sheetName };
    await appendDataIntoSheet(res);
  };
};

export const appendFormDataForDrReddyIntoSheet = (
  sheetName,
  extrasColValues
) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const ipData = store.getState().ip.data;
    const name = userData.name;
    const email = userData.email;
    const whatsAppNumber = userData.whatsAppNumber;
    const lmp = userData.lmp || "_";
    const monthOfPreg = userData?.monthOfPregnancy;
    let _notes = [
      getCurrentDate(),
      name,
      email,
      whatsAppNumber,
      lmp || monthOfPreg,
    ];
    if (extrasColValues && extrasColValues.length > 0) {
      console.log("Somethinggg ");
      console.log(extrasColValues);
      _notes = [..._notes, ...extrasColValues];
    }
    let notes = [
      [..._notes].filter((value) => value.trim().length > 0),
      // .filter(
      //   (p) => p.toString().trim().length !== 0
      // ),
    ];
    const res = { notes, sheetName };
    console.log(res);
    await appendDataIntoSheet(res);
  };
};

export const requestToBookASlot = (isInternalTeam, phase) => {
  return async (dispatch) => {
    dispatch(UiActions.setIsLoading(true));
    dispatch(UiActions.setStatus("Submitting Form..."));
    const state = store.getState();
    const data = {
      name: state.user.name,
      email: state.user.email,
      whatsAppNumber: state.user.whatsAppNumber,
      lmp: state.user.lmp,
      slotBooking: state.user.slotBooking,
      slotDocId: state.slots.newDate.id,
      slotId: state.slots.newTime.id,
      utmSource: state.user.extras.utm_source,
      utmCampaign: state.user.extras.utm_campaign,
      model: state.user.extras.model,
      brand: state.user.extras.brand,
      ttcDuration: state.user.ttcDuration,
      currentSituation: state.user.currentSituation,
      treatmentInterest: state.user.treatmentInterest,
      ipData: state.ip.data,
      isInternalTeam,
      interTeamData: {
        callingTimestamp: state.user.callingTimestamp,
        comments: state.user.comments,
        callRecordingUrl: state.user.callRecordingUrl,
        occupationDetail: state.user.occupationDetail,
        preSalesOwner: state.user.extras.pre_sales_owner,
      },
      phase,
    };
    const res = await bookSlot(data);
    if (res.result === "success") {
      dispatch(UiActions.setIsSlotBooked(true));
    } else {
      dispatch(UiActions.setError(res.message));
    }
    dispatch(UiActions.setIsLoading(false));
    dispatch(UiActions.setStatus(null));
  };
};

export const traceIpAddress = () => async (dispatch) => {
  const ipData = await getIpData();
  console.log(ipData);
  if (ipData) dispatch(ipActions.setData(ipData));
  else dispatch(ipActions.setData({}));
};

export async function triggerRetoolForDrReddyUser(userData) {
  await axios.post(
    "https://api.retool.com/v1/workflows/b5d4dee4-0289-4749-8073-57712facd222/startTrigger?workflowApiKey=retool_wk_71dfd1eed7594f708b8100093cb5051f",
    { userData: userData }
  );
}
